import moment from "moment";
import Vue from "vue";
import App from "./App.vue";

// Routers
import router from "./router";

// Plugins
import VueCurrencyInput from "vue-currency-input";
import VueGtag from "vue-gtag";
import Meta from "vue-meta";
import TiptapVuetify from "./plugins/tiptap-vuetify";
import vuetify from "./plugins/vuetify";

// Services
import KawasakiService from "./services/kawasaki.service";

// Components
import GappComponents from "./gapp-components/main";

Vue.config.productionTip = false;

Vue.use(GappComponents, {
  clientKey: process.env.VUE_APP_CLIENT_KEY,
  programGroupKey: process.env.VUE_APP_PROGRAM_GROUP_KEY,
  baseUrl: process.env.VUE_APP_LOYALTY_API_HOST_US,
  portalUrl: process.env.VUE_APP_PORTAL_URL,
  httpRedirects: { 403: { name: "accessDenied" } },
  env: process.env
});

// Plugin - Meta - Adds meta tags to pages
Vue.use(Meta);

// Plugin - VueCurrency - Allows currency formatting
Vue.use(VueCurrencyInput);

// Plugin - TiptapVuetify - WYSIWYG editor
Vue.use(TiptapVuetify, { vuetify, iconsGroup: "md" });

// Check authentication before each page
router.beforeEach((to, from, next) => {
  // eslint-disable-next-line no-constant-condition

  if (to.matched.some(record => record.meta.requiresAuthentication)) {
    router.app.$auth
      .verify()
      .then(async () => {
        // Check for Unauthorized Access
        if (!hasAccess(to.matched)) {
          return to.path != "/accessDenied" ? next("/accessDenied") : next();
        }

        if (await hasPendingParentConsent()) {
          return to.path != "/pending-consent" ? next("/pending-consent") : next();
        }

        // See if user needs to join Salesperson program
        if (needsToJoinSalesProgram()) {
          return to.path !== "/join/salesperson"
            ? next({ path: "/join/salesperson", query: { needsToJoinSalesProgram: true } })
            : next();
        }

        // See if user needs to join Racer program
        if (needsToJoinRacerProgram()) {
          return to.path != "/join/racer" ? next("/join/racer") : next();
        }

        // Check for Pre-Enrolled
        if (router.app.$auth.isPreEnrolled()) {
          return to.path != "/views/preEnroll" ? next("/views/preEnroll") : next();
        }

        // Skip profile check if the user is in the registration flow
        if (to.path.startsWith("/register") || to.path.startsWith("/join")) {
          return next(); // Do not redirect to profile, allow registration flow to continue
        }

        if (
          router.app.$participant.isMissingRequiredInformation() ||
          router.app.$organization.isMissingRequiredInformation() ||
          router.app.$participant.isMissingSecurityAnswers()
        ) {
          return to.path != "/profile"
            ? next("/profile?missing=true&moment=" + moment().format("YYYYMMDDTHHmmssSSS"))
            : next();
        }

        return next();
      })
      .catch(e => {
        console.log(e);
        if (e == "No token") {
          return to.path != "/login" ? next("/login") : next();
        } else {
          return next("/login?error=401");
        }
      });
  } else {
    next();
  }
});

// Apply Google tagging
if (process.env.VUE_APP_GTAG && process.env.VUE_APP_GTAG.length > 0) {
  Vue.use(
    VueGtag,
    {
      config: { id: process.env.VUE_APP_GTAG }
    },
    router
  );
}

// Setup Vue
let vm = new Vue({
  router: router,
  vuetify,
  render: h => h(App)
}).$mount("#app");

vm.$i18n
  .determine("US")
  .then(locale => {
    console.log("Determined locale: " + (locale ? locale.code : "None"));
  })
  .catch(e => {
    console.log(e);
  });

// Initialize Kawasaki service
KawasakiService.init(router);
Vue.prototype.$kawasaki = KawasakiService;

async function hasPendingParentConsent() {
  return router.app.$kawasaki.isRacer() && !(await router.app.$kawasaki.hasParentConsents());
}

function needsToJoinRacerProgram() {
  return router.app.$kawasaki.isRacer() && !router.app.$kawasaki.isActiveInProgram();
}

function needsToJoinSalesProgram() {
  return router.app.$kawasaki.isSalesperson() && !router.app.$kawasaki.isActiveInProgram();
}

function hasAccess(matched) {
  let participant = router.app.$store.getters.selectedParticipant;
  if (participant) {
    for (let i = 0; i < matched.length; i++) {
      let match = matched[i];
      if (match.meta && match.meta.participantTypes) {
        if (!match.meta.participantTypes.includes(participant.participantType.participantTypeKey)) {
          return false;
        }
      }
      if (match.meta && match.meta.privileges) {
        for (let j = 0; j < match.meta.privileges.length; j++) {
          let privilege = match.meta.privileges[j];
          if (!router.app.$privilege.hasPrivilege(privilege)) {
            return false;
          }
        }
      }
    }
  }
  return true;
}
